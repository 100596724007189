<!-- 报名管理 -->
<template>
  <div class="Management">
    <div class="Management_in">

      <div>
        <Tabs v-model="status" @on-click="search">
          <TabPane :label="'待查看 ·' +statusCount[0]" name="0">
          </TabPane>
          <TabPane :label="'已查看 ·'+statusCount[1]" name="10">
          </TabPane>
          <TabPane :label="'已邀约 ·'+statusCount[2]" name="150">
          </TabPane>
          <TabPane :label="'收藏 ·'+statusCount[3]" name="4">
          </TabPane>
        </Tabs>
      </div>

      <header>

        <div class="left">
          <div>
            <span>姓名检索：</span>
            <Input v-model="name" placeholder="搜索姓名" style="width: 250px" @on-enter="search" />
          </div>

          <div style="margin-left:30px">
            <span>岗位检索：</span>
            <Input v-model="job" placeholder="搜索岗位" style="width: 250px" @on-enter="search" />
          </div>
        </div>

        <div class="right">
          <Button type="primary" @click="search()">搜索</Button>
        </div>

      </header>

      <article>
        <Table max-height="640" :loading="loading" :columns="columns1" :data="Tabledata">
          <!-- 面试邀约 -->
          <template slot-scope="{ row }" slot="invite">
            <Button type="primary" size="small" @click="$router.push({name:'interview_manage_detail',query:{id:row._id}})">详情</Button>
          </template>

          <!-- 查看 -->
          <template slot-scope="{ row }" slot="details">
            <Button type="primary" size="small" @click="go_details(row)">查看</Button>
          </template>
        </Table>
      </article>

      <footer>
        <footer>
          <PageSize :total="total" @page_size="page_size" :page_in="obj.page" :size_in="obj.size" />
        </footer>
      </footer>
    </div>
  </div>
</template>

<script>
import { data12, data3, data4 } from "./Form_header"
import PageSize from "@/components/PageSize.vue"
export default {
  data() {
    return {
      total: 0,
      loading: false,
      statusCount: ["", "", "", "",],
      status: "0",

      name: "",
      job: "",
      obj: {
        mobile: localStorage.getItem("firm_mobile"),
        page: 1,
        size: 10
      },
      collect_obj: {
        ent_id: localStorage.getItem('firm_enterpriseId'),
        page: 1,
        size: 10,
      },

      columns1: data12,

      Tabledata: []
    }
  },
  components: {
    PageSize
  },
  methods: {
    //获取分页
    page_size(page, size) {


      if (this.status == 150) {
        this.columns1 = data3
      } else if (this.status == 4) {
        this.Tabledata = [{ cllectList: [{ name: '' }] }]
        this.columns1 = data4
      } else {
        this.columns1 = data12
      }
      if (this.status == 4) {
        this.collect_obj.page = page
        this.collect_obj.size = size
        this.get_data_collect()
      } else {
        this.obj.page = page
        this.obj.size = size
        this.get_data()
      }
    },
    //搜索
    search() {
      console.log(this.status);
      if (this.status == 150) {
        this.columns1 = data3
      } else if (this.status == 4) {
        this.Tabledata = [{ cllectList: [{ name: '' }] }]
        this.columns1 = data4
      } else {
        this.columns1 = data12
      }
      this.obj.page = 1
      this.obj.size = 10
      this.collect_obj.page = 1
      this.collect_obj.size = 10

      if (this.status == 4) {
        this.get_data_collect()
      } else {
        this.get_data()
      }
    },
    //改变tab时
    selectTab(name) {
      if (name == 150) {
        this.columns1 = data3
      } else if (name == 4) {
        this.Tabledata = [{ cllectList: [{ name: '' }] }]
        this.columns1 = data4
      } else {
        this.columns1 = data12
      }
      if (name == 4) {
        this.get_data_collect()
      } else {
        this.get_data()
      }
    },


    //查看
    go_details(row) {
      this.$router.push(
        {
          name: 'resume_manage_detail',
          params: this.status == 4 ? { id: row.resume_id, type: row.type, date: row.time } : { id: row._id, type: row.resumeType, date: row.entryDate }
        })
    },
    //封装获取数据
    get_data() {
      this.loading = true
      this.obj.status = +this.status
      this.obj.key = this.name
      this.obj.name = this.job
      this.app("enterpriseOperators", "myResumes", this.obj).then(res => {
        console.log(res);
        this.statusCount = res.count
        this.Tabledata = res.result
        this.total = this.status == '0' ? res.count[0] : this.status == '10' ? res.count[1] : res.count[2]
        this.loading = false
      })
    },
    //封装获取收藏数据
    get_data_collect() {
      this.loading = true
      this.collect_obj.key = this.name
      this.collect_obj.name = this.job
      this.app("enterpriseOperators", "getCollectResumes", this.collect_obj).then(res => {
        console.log(res);
        this.loading = false
        this.Tabledata = res.data
        this.total = res.pageCount.count


      })
    }
  },
  created() {
    this.get_data()
  }
}
</script>

<style lang="less" scoped>
@import "./Management.less";
/deep/.ivu-tabs-ink-bar {
  background-color: red;
}
/deep/.ivu-tabs-tab-active {
  color: #515a6e;
}
</style>
